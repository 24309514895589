export const URL_LOCATIONS=Object.freeze({HOME:"HOME",PRIVACY:"PRIVACY",TERMS:"TERMS"});const MATCH_REGEX=/^\/(\w+)/g;/**
 * Returns the portion of "/<url>" from the URL path, which may include
 * query parameters, like "/<url>?a=b".
 * @param {string} pathName - the URL path
 * @returns {string}
 */function getPath(pathName){let path=pathName.match(MATCH_REGEX);if(path===null){// TODO: Is it always the case that if we don't match anything
// in the REGEX, it's the home page? Not sure. I think it might.
return"/";}if(path.length===0){return null;}return path[0].toLowerCase();}const UrlLocationUtils={/**
   * Returns whether this is the homepage URL based on the URL path
   * @param {string} pathName
   * @returns {boolean}
   */isHome(pathName){const path=getPath(pathName);return path==="/"||path==="/home";},/**
   * Returns whether this is the privacy URL based on the URL path
   * @param {string} pathName
   * @returns {boolean}
   */isPrivacy(pathName){const path=getPath(pathName);return path==="/privacy";},/**
   * Returns whether this is the terms of service URL based on the URL path
   * @param {string} pathName
   * @returns {boolean}
   */isTerms(pathName){const path=getPath(pathName);return path==="/terms";}};export default UrlLocationUtils;